.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* Basic editor styles */
.ProseMirror {
    outline: none;
    min-height: 240px;
}

.ProseMirror > * + * {
    margin-top: 0.75em;
}

.ProseMirror ul,
ol {
    padding: 0 1rem;
}

.ProseMirror h1,
h2,
h3,
h4,
h5,
h6 {
    line-height: 1.1;
}

.ProseMirror code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
}

.ProseMirror pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
}

ProseMirror pre code {
    color: inherit;
    padding: 0;
    background: none;
    font-size: 0.8rem;
}

.ProseMirror img {
    max-width: 100%;
    height: auto;
}

.ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0D0D0D, 0.1);
}

.ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(#0D0D0D, 0.1);
    margin: 2rem 0;
}

.ProseMirror .mention {
  border: 1px solid #000;
  border-radius: 0.4rem;
  padding: 0.1rem 0.3rem;
  margin: 0 0.2rem;
  box-decoration-break: clone;
}

@keyframes fadeIn {
    0% {
        transform: scale(0);
        opacity: 0.0;
    }
    60% {
        transform: scale(1.03);
    }
    80% {
        transform: scale(0.97);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        -webkit-transform: scale(0);
        opacity: 0.0;
    }
    60% {
        -webkit-transform: scale(1.1);
    }
    80% {
        -webkit-transform: scale(0.9);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 1;
    }
}